import React, { Component } from 'react';
import logo from './logo.svg';
import yungkev from './yungkev.jpg';
import './App.css';

class App extends Component {
  url = '';
  place = '';
  recommend() {
    const places = [
      'Codmother',
      'Service Bar DC',
      'Wonderland Ballroom',
      'Sudhouse',
      'Brixton',
      'Lost Society',
      'Cafe Saint-Ex',
    ];

    const phrases = [
      'Might I suggest ',
      'Here\'s a cool place, ',
      'This sounds like a time for ',
      'Maybe you should check out ',
      'Yeah, go check out ',
    ];

    const phrase = phrases[Math.floor(Math.random()*phrases.length)];
    const place = places[Math.floor(Math.random()*places.length)];

    const saying =  phrase + place;

    this.url = `http://www.lmgtfy.com/?q=${place}`;
    this.place = place;

    return saying;
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={yungkev} className="App-logo" alt="logo" />
          <p>
            {this.recommend()}
          </p>
          <a
            className="App-link"
            href={this.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn about {this.place}
          </a>
        </header>
      </div>
    );
  }
}

export default App;
